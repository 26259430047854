import { Avatar, Box, Card, CardContent, CircularProgress, Container, Grid, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useGetEmptyJwtQuery } from 'src/redux/api/auth';


export const ReviewsLandingIndex = () => {
    const { data, isSuccess, isLoading, isError } = useGetEmptyJwtQuery({ route: 'get-reviews' })

    return (
        <>

            <Box sx={{
                pt: 5,
                pb: 10,
                display: isError || data && data.data.length == 0 ? 'none' : 'block'
            }}>
                <Container>
                    <Typography textAlign='center' variant='h2' mb={2}>
                        Отзывы
                    </Typography>

                    <Box>
                        {isSuccess &&
                            <Swiper
                                loop={true}
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 10000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper review-slider"
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                }}
                            >

                                {data && data.data.map((e: any) => (
                                    <SwiperSlide key={e.id} style={{ display: 'flex' }}>
                                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                            <Box display='flex' mb={2} alignItems='center'>
                                                <Avatar
                                                    sx={{ mr: 1 }}
                                                    alt={e.author}
                                                    src={!!e.avatar ? e.avatar : ''}
                                                />
                                                <Typography mb={1} textAlign='center' sx={{ fontSize: "1rem", fontWeight: 600 }}>
                                                    {e.author} <Typography component='span' sx={{ color: 'red', pl: 1 }}>{e.created_at}</Typography>
                                                </Typography>
                                            </Box>

                                            <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mb: 3, px: 5 }}>
                                                {e.comment}
                                            </Typography>
                                        </Box>
                                    </SwiperSlide>
                                ))}


                            </Swiper>
                        }
                        {/* <Grid container spacing={2}>
                            {isSuccess && data.data.map((e: any) => (
                                <Grid key={e.id} item xs={12}>
                                    <Card >
                                        <CardContent>
                                            <Box display='flex' mb={2} alignItems='center'>
                                                <Avatar
                                                    sx={{ mr: 1 }}
                                                    alt={e.author}
                                                    src={!!e.avatar ? e.avatar : ''}
                                                />
                                                <Typography mb={1} textAlign='center' sx={{ fontSize: "1rem", fontWeight: 600 }}>
                                                    {e.author} <Typography component='span' sx={{ color: 'red', pl: 1 }}>{e.created_at}</Typography>
                                                </Typography>
                                            </Box>

                                            <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mb: 3 }}>
                                                {e.comment}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}

                        </Grid> */}
                        {isLoading && <Box minHeight='25vh' display='flex' justifyContent='center' alignItems='center'>

                            <CircularProgress />
                        </Box>}

                    </Box>
                </Container>
            </Box>

        </>
    )
}
