import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { usePostBodyWithJwtMutation } from 'src/redux/api/cabinet';
import { palette } from 'src/theme/style/palette';

export const ListMyReviews = () => {

    const [data, setData] = useState<undefined | any[]>(undefined);
    const [err, setErr] = useState<string>('');


    const [postData, { isSuccess, isLoading, isError }] = usePostBodyWithJwtMutation();

    const loginAnswer = async (data: any) => {
        const jwt = localStorage.getItem('jwt');
        await postData({ body: data, jwt, route: "review/get" })
            .unwrap()
            .then((payload: any) => {
                setData(payload)
            })
            .catch((err) => {
                setErr(err.data.message)
            })
    }

    useEffect(() => {
        loginAnswer([])
    }, []);

    return (
        <Box py={2}>
            <Grid container spacing={1}>
                {isError &&
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ p: 0 }}>
                                <Typography mt={2} sx={{ fontWeight: 700 }} color='error' variant='h5' textAlign='center'>Ошибка</Typography>
                                <Typography mt={2} sx={{ fontWeight: 600 }} color='error' variant='h6' textAlign='center'>{err}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                {data && isSuccess && <>
                    {data?.map((e: any) => (
                        <Grid key={e.id} item xs={12} md={6} display='flex'>
                            <Card>
                                <CardContent sx={{ p: 0 }}>
                                    <Chip
                                        sx={{ background: e.status == 0 ? palette.infoLight : e.status == 1 ? palette.successLight : palette.errorLight }}
                                        label={e.status == 0 ? "На модерации" : e.status == 1 ? 'Опубликовано' : 'Отменено'} />
                                    <Typography mt={2} sx={{ fontWeight: 600 }}>{e.comment}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                    }

                </>}


            </Grid>
        </Box>
    )
}
