import { Label } from "@mui/icons-material"
import { Box, Button, Card, CardContent, FormControl, InputLabel, OutlinedInput, TextField } from "@mui/material"
import { useState } from "react";
import { useForm, } from 'react-hook-form';
import { usePostBodyWithJwtMutation } from "src/redux/api/cabinet";
import { Alerting } from "src/views/components_cabinet/Alerting";

export const ReviewBody = () => {
    // const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
        // console.log('Отправка данных ');
        // console.log(data);
        loginAnswer(data);
    }

    const [postData, { isSuccess, isLoading }] = usePostBodyWithJwtMutation();
    const loginAnswer = async (data: any) => {
        const jwt = localStorage.getItem('jwt');
        await postData({ body: data, jwt, route: "review/add" })
            .unwrap()
            .then((payload: any) => {
                setgetAlert({ type: 1, msg: payload.message });
                setValue('comment', '');
            })
            .catch((err: any) => {
                console.log(err);
                setgetAlert({ type: 2, msg: err.data.message });


            })
    }

    return (
        <>
            {getAlert && <Alerting get={getAlert} />}
            <Box>
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                    <label>
                        Текст отзыва
                    </label>
                    <FormControl fullWidth sx={{ m: 0, p: 0 }} variant="outlined" >
                        {/* <InputLabel htmlFor="outlined-adornment-password">Текст отзыва</InputLabel> */}

                        <OutlinedInput
                            error={!!errors.comment}
                            {...register("comment", {
                                required: {
                                    value: true,
                                    message: "Текст комментария обязателен"
                                },
                                minLength: {
                                    message: "Минимальная длинна комментария 10 символов",
                                    value: 10
                                }
                            })}
                            id="outlined-adornment-password"
                            // label="Текст отзыва"
                            multiline
                            rows={8}
                            sx={{
                                background: '#ededed',
                                "&>.MuiOutlinedInput-notchedOutline": {
                                    background: 'transparent',
                                    zIndex: 10
                                },
                                "&>textarea": {
                                    background: '#ededed',
                                    zIndex: 100
                                },

                            }}
                        />
                        <Box mt={2}>
                            <Button disabled={isLoading} type="submit">Отправить отзыв</Button>
                        </Box>
                    </FormControl>

                </form>
            </Box>
        </>
    )
}
