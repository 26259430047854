import { CredoIndex } from "./components/CredoIndex"
import { MarketingIndex } from "./components/MarketingIndex"
import { OurClubs } from "./components/OurClubs"
import { ReviewsLandingIndex } from "./components/reviews-landing/ReviewsLandingIndex"
import { SliderIndex } from "./components/SliderIndex"
import { WhyWeIndex } from "./components/WhyWeIndex"

export const IndexLanding = () => {
    return (
        <>

            {/* ниже прокидываем компоненты для разметки лендинга */}
            <SliderIndex />
            <CredoIndex />

            {/* раздел для payeer преимущества */}
            <OurClubs />

            <WhyWeIndex />

            <MarketingIndex />

            <ReviewsLandingIndex />
        </>
    )
}
