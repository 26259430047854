import React from 'react'
import { PageTitleLayout } from 'src/layouts/pages/PageTitleLayout'
import { ReviewBody } from './ReviewBody'
import { ListMyReviews } from './ListMyReviews'
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useGetEmptyJwtQuery } from 'src/redux/api/auth'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export const ReviewsOfficeIndex = () => {
    const [open, setOpen] = React.useState(false);
    const { data, isSuccess, isLoading, isError } = useGetEmptyJwtQuery({ route: 'get-reviews' })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <PageTitleLayout
                title="Отзывы"
                body={<>

                    <Box>
                        <Button variant='contained' onClick={handleClickOpen}>Написать отзыв</Button>

                        <Box my={5} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Box sx={{ width: { xs: '100%', lg: '70vw' } }}>
                                {isSuccess &&
                                    <Swiper
                                        loop={true}
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        autoplay={{
                                            delay: 10000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="mySwiper review-slider"
                                    >

                                        {data && data.data.map((e: any) => (
                                            <SwiperSlide key={e.id} style={{ display: 'flex' }}>
                                                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                                    <Box display='flex' mb={2} alignItems='center'>
                                                        <Avatar
                                                            sx={{ mr: 1 }}
                                                            alt={e.author}
                                                            src={!!e.avatar ? e.avatar : ''}
                                                        />
                                                        <Typography mb={1} textAlign='center' sx={{ fontSize: "1rem", fontWeight: 600 }}>
                                                            {e.author} <Typography component='span' sx={{ color: 'red', pl: 1 }}>{e.created_at}</Typography>
                                                        </Typography>
                                                    </Box>

                                                    <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mb: 3, px: 5 }}>
                                                        {e.comment}
                                                    </Typography>
                                                </Box>
                                            </SwiperSlide>
                                        ))}


                                    </Swiper>
                                }

                            </Box>


                            {isLoading && <Box minHeight='25vh' display='flex' justifyContent='center' alignItems='center'>
                                <CircularProgress />
                            </Box>}

                        </Box>




                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Создать отзыв?"}
                            </DialogTitle>
                            <DialogContent>
                                <ReviewBody />
                                <ListMyReviews />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color='error'>Отмена</Button>
                            </DialogActions>
                        </Dialog>


                    </Box>
                </>}
            />
        </>
    )
}
