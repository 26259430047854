const { REACT_APP_BACK } = process.env;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const auth = createApi({
  reducerPath: 'auth',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_BACK,
  }),
  endpoints: (builder) => ({
    // getauthHome: builder.query<any[], string>({
    //   query: (route) => `api/${route}`,
    // }),

    // getauthHome: builder.query({
    //   query: ({ route }) => `api/${route}`,
    // }),

    getEmptyJwt: builder.query({
      query: ({ body, route }) => ({
        url: `api/${route}`,
        method: 'get',
        body,
        // headers: {
        //   Accept: 'application/json',
        //   'Content-Type': 'application/json',
        // },
      }),
    }),

    registr: builder.mutation({
      query: ({ body }) => ({
        url: `api/logup`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    }),
    login: builder.mutation({
      query: ({ body }) => ({
        url: `api/login`,
        method: 'POST',
        // formData: true,
        body,
        headers: {
          Accept: 'application/json',
        },
      }),
    }),
    checkAuth: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/check_auth`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    checkRefer: builder.mutation({
      query: ({ body }) => ({
        url: `api/check_referer`,
        method: 'POST',
        // formData: true,
        body,
        headers: {
          Accept: 'application/json',
        },
      }),
    }),
    logOut: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/logout`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    refreshPassword: builder.mutation({
      query: ({ body }) => ({
        url: `api/refresh-password`,
        method: 'POST',
        body,
        headers: {
          Accept: 'application/json',
          // Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
  }),
});

export const {
  useGetEmptyJwtQuery,
  useLoginMutation,
  useRegistrMutation,
  useCheckAuthMutation,
  useCheckReferMutation,
  useLogOutMutation,
  useRefreshPasswordMutation,
} = auth;
