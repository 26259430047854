import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePayMarketingMutation, useViewPositionMutation } from "src/redux/api/cabinet";
import { Structure } from "./Structure";
import { SkeletonComp } from "./SkeletonComp";
import { Levels } from "./Levels";
import { PaymantContainer } from "./PaymantContainer";
import { useTimer } from 'react-timer-hook';
import { useSelector } from "react-redux";
import { AppState } from "src/store/Store";
import { useDispatch } from "react-redux";
import { updActiveTimer } from "src/redux/slices/app";
import { Alerting } from "src/views/components_cabinet/Alerting";
import { StructureChain } from "./StructureChain";
import { Box, Button, Checkbox, Drawer, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, MenuItem, Select, SelectChangeEvent, Theme, Grid, Typography, useMediaQuery } from "@mui/material";
import { RightDrawer } from "./RightDrawer";
// import './marketingslider.module.scss'


// import { Alerting } from "src/views/components_cabinet/Alerting";

const programs = [
    { name: 'MINI', id: 1, level: 1100 },
    { name: 'MIDI', id: 2, level: 5000 },
    { name: 'MAXI', id: 3, level: 25000 },
    { name: 'AUTO', id: 4, level: 300000 },
    { name: 'HOME', id: 5, level: 3000000 },
    { name: 'SPACE', id: 6, level: 1000 },
    { name: 'NULL', id: 7, level: 600 },
    { name: 'TAKE', id: 8, level: 110 },
    { name: 'SHOT', id: 9, level: 10100 },
];

interface IMyTimer {
    expiryTimestamp: any
}

const MyTimer: FC<IMyTimer> = ({ expiryTimestamp }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const [searchParams] = useSearchParams();
    // const programParam = searchParams.get('p');
    // const levelParam = searchParams.get('l');
    // const mentorParam = searchParams.get('m');

    const {
        // totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        // isRunning,
        // start,
        // pause,
        // resume,
        // restart,
    } = useTimer({
        expiryTimestamp, onExpire: () => {
            dispatch(updActiveTimer({ state: false }));
            // navigate();

        }
    });

    return (
        <Box style={{ minHeight: '120px' }} mt={10}>
            <Box display='flex' justifyContent='center'>
                {/* <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span> */}
                <Typography textAlign='center' variant="h2" sx={{ fontSize: { xs: "20px", sm: "34px", lg: "50px" }, lineHeight: "60px", color: "red" }}>
                    {days < 10 ? `0${days}` : days} <Typography component='span' sx={{ fontSize: { xs: "14px", sm: "26px", lg: "28px" }, fontWeight: 500 }}>{days === 1 ? "День" : days > 1 && days < 5 ? "Дня" : "Дней"}</Typography>&nbsp;:&nbsp;
                    {hours < 10 ? `0${hours}` : hours} <Typography component='span' sx={{ fontSize: { xs: "14px", sm: "26px", lg: "28px" }, fontWeight: 500 }}>{hours === 1 || hours === 21 ? "Час" : hours > 1 && hours < 5 || hours > 21 && hours < 25 ? "Часа" : "Часов"}</Typography>&nbsp;:&nbsp;
                    {minutes < 10 ? `0${minutes}` : minutes} <Typography component='span' sx={{ fontSize: { xs: "14px", sm: "26px", lg: "28px" }, fontWeight: 500 }}>{minutes % 10 === 1 ? "Минута" : minutes % 10 > 1 && minutes % 10 < 5 ? "Минуты" : "Минут"}</Typography>&nbsp;:&nbsp;
                    {seconds < 10 ? `0${seconds}` : seconds} <Typography component='span' sx={{ fontSize: { xs: "14px", sm: "26px", lg: "28px" }, fontWeight: 500 }}>{seconds % 10 === 1 ? "Секунда" : seconds % 10 > 1 && seconds % 10 < 5 ? "Секунды" : "Секунд"}</Typography>&nbsp;
                </Typography>
            </Box>
        </Box>
    );
}

export const ProgramsOfficePage = () => {
    const { activeTimer } = useSelector((state: AppState) => state.app);
    const [searchParams] = useSearchParams();
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const [positionsArr, setPositionsArr] = useState<any[] | undefined>(undefined);
    const [globalClone, setGlobalClone] = useState<boolean>(false);
    const [errtext, setErrtext] = useState<string>('');
    const [nowProgram, setNowProgram] = useState<any>(undefined);
    const [needPaymantBtn, setNeedPaymantBtn] = useState<boolean>(false);
    const [paymantUserStatus, setPaymantUserStatus] = useState<boolean>(true);
    const [levels, setLevels] = useState<any[]>([]);
    const [m_type, setm_type] = useState<number>(2);
    const [listClones, setListClones] = useState<any[] | undefined>(undefined);
    const [listHistory, setListHistory] = useState<any[]>([]);
    const [subscribe, setSubscribe] = useState<{ subscribe: boolean, subscribe_comment: string, subscribe_error: boolean, subscribe_paid: boolean } | undefined>(undefined);
    const [fullData, setFullData] = useState<string>('');

    const programParam = searchParams.get('p');
    const levelParam = searchParams.get('l');
    const mentorParam = searchParams.get('m');

    const dispatch = useDispatch();

    const onSubmit = (data: any) => {
        // console.log('Отправка данных ');
        // console.log(data);
        setErrtext('')
        levelsRequest(data);
    }

    const [levelsData, { isLoading, isError }] = useViewPositionMutation();
    const levelsRequest = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await levelsData({ body, jwt })
            .unwrap()
            .then((payload: any) => {

                // console.log("payload");
                // console.log(payload);
                setFullData(payload);
                setPaymantUserStatus(payload.paymant);
                setGlobalClone(payload.global_clone)
                setPositionsArr(payload.data);
                setLevels(payload.levels);
                setm_type(payload.marketing_type);
                setListClones(payload.list_clones);
                setListHistory(payload.list_histori);

                setSubscribe({
                    subscribe: payload.subscribe,
                    subscribe_comment: payload.subscribe_comment,
                    subscribe_error: payload.subscribe_error,
                    subscribe_paid: payload.subscribe_paid
                });

                if (payload.tester === true && programParam === "6") dispatch(updActiveTimer({ state: !payload.tester }))

            })
            .catch((error: any) => {
                // console.log('err levelsData');
                // console.log(error);
                setLevels(error.data.levels);
                setErrtext(error.data.message);
                // setgetAlert({ type: 2, msg: error.data.message });
                updateNeedPaymant(error.data.paymant);
                if (error.data.tester === true && programParam === "6") dispatch(updActiveTimer({ state: !error.data.tester }))
            })
    }

    const updateNeedPaymant = (status: boolean) => {
        setNeedPaymantBtn(status)
    }

    const updErrText = (text: string) => setErrtext(text);

    const payCloneForPloshadka = (request: any) => {
        // console.log(request);
        Request(request);
    }

    const [reqData, { isLoading: isLd, isSuccess: isSc }] = usePayMarketingMutation();
    const Request = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await reqData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                // setStateModal(p => !p)
                // window.location.reload()
                setgetAlert({ type: 1, msg: payload.message });

            })
            .catch((error: any) => {

                // setLevels(error.data.levels);
                // setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    const time = new Date("Thu Sep 04 2024 17:00:00 GMT+0300 (Москва, стандартное время)");
    // const time = new Date("Thu Sep 04 2024 14:22:00 GMT+0300 (Москва, стандартное время)");

    // time.setSeconds(time.getSeconds());
    // timeNow.setSeconds(timeNow.getSeconds());
    // time.setSeconds();
    // console.log(timeNow.getTime());

    useEffect(() => {
        const requestParams = {
            program: programParam,
            level: levelParam,
            mentor_id: mentorParam
        };

        // setPrms(requestParams);
        onSubmit(requestParams);

        programs.forEach((el) => {
            if (el.id === Number(programParam)) setNowProgram(el);
        });

    }, [searchParams]);

    return (
        <>
            {getAlert && <Alerting get={getAlert} />}

            {/* таймер */}
            {activeTimer && new Date().getTime() < time.getTime() && isError && programParam === '6' ? <>
                <Box sx={{ minHeight: '500px' }}>
                    <Typography variant="h1" textAlign='center' sx={{ fontSize: { xs: "30px", sm: '36px', lg: "48px" }, mt: { xs: 8, sm: 15, lg: 20 } }}>
                        До СТАРТА платформы <q>SPACE</q> осталось:
                    </Typography>
                    <MyTimer expiryTimestamp={time} />
                </Box>
            </> :
                <>
                    {isLoading && <SkeletonComp />}

                    {/* всплывающее меню справа */}
                    {!isLoading && <RightDrawer />}

                    {/* цепь структуры */}
                    {!isLoading &&
                        <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' pt={0} pb={0}>
                            <StructureChain arrChain={listHistory} />
                        </Box>
                    }

                    {/* levels */}
                    {!isLoading && <Levels levels={levels} nowProgram={nowProgram} />}

                    {/* успешная загрузка выводим структуру*/}
                    {!isLoading && positionsArr && !isError &&
                        <Structure fullData={fullData} paymantUserStatus={paymantUserStatus} globalClone={globalClone} listHistory={listHistory} subscribe={subscribe} structure={positionsArr} program={nowProgram} marketing_type={m_type} list_clones={listClones ? listClones : []} />
                    }

                    {/* если ошибка при загрузке */}
                    {isError &&
                        <Box height='70vh' width='100%' display='flex' textAlign='center' justifyContent='center' alignItems='center' flexDirection='column'>
                            <Typography variant="h1" color='error' mb={3}>
                                {errtext}
                            </Typography>

                            {needPaymantBtn && <PaymantContainer updateNeedPaymant={updateNeedPaymant} updErrText={updErrText} paymantData={{ program: Number(programParam), level: Number(levelParam), mentor_id: Number(mentorParam) }} />}

                            {/* покупка клона на программе 6/100 руб */}
                            {isError && !needPaymantBtn && programParam === "6" && <Box mt={5}>
                                <Typography sx={{ mb: 1, fontSize: "16px", fontWeight: 600 }}>Приобритение дополнительного клона</Typography>

                                <Button disabled={isLd} fullWidth onClick={() => payCloneForPloshadka({
                                    program: programParam,
                                    level: levelParam
                                })}>
                                    Купить клона ЖО
                                </Button>
                            </Box>}
                        </Box>
                    }
                </>
            }
        </>
    )
}
