

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, FormControl, FormLabel, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useFinanceOutMutation } from 'src/redux/api/cabinet';
import { updProfileData } from 'src/redux/slices/app';
import { AppState } from 'src/store/Store';
import { palette } from 'src/theme/style/palette';
import { Alerting } from 'src/views/components_cabinet/Alerting';

export const FormWithdrawBalance = () => {
    const user = useSelector((state: AppState) => state.app);

    const [showPassword, setShowPassword] = React.useState(false);
    // const [login, setLogin] = React.useState<string>('');
    const [amount, setAmount] = React.useState<number>(0);
    const [comment, setComment] = React.useState<string>('');

    const [FP, setFP] = React.useState<string>('');
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const dispatch = useDispatch();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const SubmitForm = () => {
        // if (login.length === 0) {
        //     setgetAlert({ type: 2, msg: 'Укажите логин получателя' });
        //     return false
        // }


        if (amount === 0) {
            setgetAlert({ type: 2, msg: 'Укажите сумму вывода' });
            return false
        }
        if (FP.length < 8) {
            setgetAlert({ type: 2, msg: 'Укажите Финансовый пароль (от 8 символов)' });
            return false
        }

        const data = { finance_password: FP, amount, comment };
        getRequest(data);
    }

    const [getData, { isLoading }] = useFinanceOutMutation();
    const getRequest = async (data: any) => {
        const jwt = localStorage.getItem('jwt');
        await getData({ body: data, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('getPartnersData');
                // console.log(payload);
                dispatch(updProfileData(payload.data));
                setgetAlert({ type: 1, msg: payload.message });
                // setLogin('');
                setAmount(0);
                setFP('');
            })
            .catch((error: any) => {
                // console.log('err getPartnersData');
                // console.log(error);
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }
    const checkNumberInput = (inputText: any) => {
        const justNumbers = inputText.replace(/[^+\d]/g, "");
        const justNumbers2 = justNumbers.replace("+", "");
        return Number(justNumbers2);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {/* <FormControl sx={{ width: '100%', mb: 4 }}>
                    <FormLabel htmlFor='login'>
                        Логин получателя
                    </FormLabel>
                    <TextField value={login} size='medium' id="login" variant="outlined"
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setLogin(el.target.value)}
                        placeholder="Логин"
                    />
                </FormControl> */}
                <FormControl sx={{ width: '100%', mb: 4 }}>
                    <FormLabel htmlFor='amount'>
                        Сумма вывода
                    </FormLabel>
                    <TextField value={amount === 0 ? '' : amount} inputMode='numeric' size='medium' id="amount" variant="outlined" type='string'
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setAmount(checkNumberInput(el.target.value))}
                        placeholder="Сумма вывода"
                        inputProps={{
                            inputMode: 'numeric',
                            autoComplete: 'off',
                        }}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%', mb: 4, position: 'relative' }}>
                    {/* <FormLabel htmlFor='fin'>
                        
                    </FormLabel>
                    <TextField size='medium' id="fin" type='password' label="Финансовый пароль" variant="outlined" /> */}
                    <Box display='flex' justifyContent='space-between' alignItems='end' mb={1}>
                        <FormLabel htmlFor='finpas'>
                            Финансовый пароль
                        </FormLabel>
                        {/* <Box component={Button} size='small' sx={{ background: 'transparent' }}>
                            Забыли пароль?
                        </Box> */}
                    </Box>

                    <OutlinedInput
                        value={FP}
                        id="finpas"
                        type={showPassword ? 'text' : 'password'}
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setFP(el.target.value)}
                        inputProps={{
                            autoComplete: 'off',
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff color='error' /> : <Visibility color='error' />}
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="Финансовый пароль"
                    />
                </FormControl>

                <FormControl sx={{ width: '100%', mb: 4 }}>
                    <TextField value={comment}
                        onInput={(el: ChangeEvent<HTMLInputElement>) => setComment(el.target.value)}
                        placeholder="Отзыв"
                        label="Отзыв"
                        multiline
                        rows={4}
                        sx={{
                            "&>.MuiInputBase-root": {
                                background: '#ededed',
                                "&>.MuiOutlinedInput-notchedOutline": {
                                    background: "transparent"
                                }
                            }
                        }}
                        inputProps={{
                            // inputMode: 'numeric',
                            autoComplete: 'off',
                        }}
                    />
                </FormControl>
                <Button onClick={SubmitForm} color='primary' variant='contained' sx={{ width: '100%' }} disabled={isLoading || user.data.balance < amount}>
                    {isLoading ? "Обработка..." : amount === 0 ? "Вывести" : `Вывести ${new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(amount))} из ${new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(user.data.balance))}`}
                </Button>

                {/* <Button type="submit" disabled={isLoading} onClick={getPayLink} variant='contained' color='primary' fullWidth>
                    {isLoading ? "Обработка..." : summ === 0 ? "Пополнить" : `Пополнить на сумму ${new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(summ))}.`}
                </Button> */}

            </Box>
            {getAlert && <Alerting get={getAlert} />}
        </>
    )
}
