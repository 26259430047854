import { Box, Typography } from "@mui/material"
import { FC, ReactNode } from "react"

interface IPageTitleLayout {
    body: ReactNode
    title: string
}

export const PageTitleLayout: FC<IPageTitleLayout> = ({ body, title }) => {
    return (
        <>
            <Typography sx={{ mb: 2 }} component='h2' variant="h5">{title}</Typography>

            <Box>
                {body}
            </Box>
        </>
    )
}
