export const palette = {
  blue: '#007aff',
  blueLight: '#151ee94a',
  blueHover: '#151ee9c9',
  textGray: '#8c8c8c',
  infoLight: '#397bff4a',
  success: '#ededed',
  successLight: '#31ffa761',
  error: 'red',
  errorLight: '#ff000033',
};
